import { useNavigate, useParams } from "react-router-dom";
import PresentationModal from "../../organisms/PresentationModal";
import useFetch from "../../atoms/useFetch";
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import { Fragment } from "react";
import Loading from "../../ions/Loading";


const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals`;

const BiroDocumentModal = () => {

    const navigate = useNavigate();

    const { referralId, providerName } = useParams();

    const { isLoading, data, error, isTokenError } = useFetch(`${BASE_REFERRAL_URL}/${referralId}/documents/${providerName}`);

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    return (
        <PresentationModal
            id='biro-document-modal'
            show={true}
            onClose={() => navigate(-1)}
            header={`Visualizar Documento: ${providerName}`}
        >
            {{
                body: (
                    <Fragment>
                        {isLoading ? <Loading /> :
                            <Fragment>
                                <div className='p-4' id="modalDataId">
                                    <div style={{ height: '500px', overflowY: 'auto' }}>
                                        {
                                            data ? (
                                                <JsonView data={data}
                                                    shouldExpandNode={allExpanded}
                                                    style={defaultStyles}
                                                />)
                                                : (null)
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                )
            }}
        </PresentationModal>
    );
};

export default BiroDocumentModal;