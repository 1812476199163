import PageHeader from "../../molecules/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useFetch from "../../atoms/useFetch";
import Loading from "../../ions/Loading";
import AsyncSelect from 'react-select/async';
import ManagerSelectGroup from "./ManagerSelectGroup";

type Option = {
    label: string,
    value: string,
}

type Manager = {
    id: string,
    name: string,
}

type Partner = {
    partnerId: number,
    partnerName: string,
    internalManager: Manager
    externalManager: Manager
}

type PartnerOption = {
    value: string;
    label: string;
    partner?: Partner;
}

const BASE_BACKOFFICE_URL = `${process.env.REACT_APP_BACKOFFICE_URL}/services/commercial`;

const CommercialFormPage = () => {

    const navigate = useNavigate();

    const [showForm, setShowForm] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);

    const partnersOptions = useRef<PartnerOption[]>([]);

    const partnerAssociated = useFetch(`${BASE_BACKOFFICE_URL}/all-partners-associated`)

    const [partner, setPartner] = useState<Partner>({
        partnerId: 0,
        partnerName: '',
        internalManager: { id: '', name: '' },
        externalManager: { id: '', name: '' },
    });

    const [internalOption, setInternalOption] = useState<Option>({
        label: 'Vazio...',
        value: '',
    });

    const [externalOption, setExternalOption] = useState<Option>({
        label: 'Vazio...',
        value: '',
    });

    const updates = () => {
        setInternalOption({
            label: partner.internalManager?.name ?? 'Vazio...',
            value: partner.internalManager?.id ?? '',
        });
        setExternalOption({
            label: partner.externalManager?.name ?? 'Vazio...',
            value: partner.externalManager?.id ?? '',
        });
    }

    useEffect(() => {

        setRefreshForm(true);
        updates();
        if (partner.partnerId !== 0) {
            setShowForm(true);
        }

        if (partner.partnerId === 0) {
            setShowForm(false);
        }

        setTimeout(() => {
            setRefreshForm(false);
        }, 1000)

        return () => {

        }

    }, [partner])

    if (partnerAssociated.isTokenError) {
        navigate("/denied");
    }
    if (partnerAssociated.error) {
        navigate("/network-error");
    }
    if (partnerAssociated.data) {
        partnersOptions.current = partnerAssociated.data.map((partner: Partner) => {
            return {
                value: partner.partnerId.toString(),
                label: partner.partnerName,
                partner: partner
            }
        })
        partnersOptions.current.unshift({
            value: '',
            label: 'Vazio...'
        })
    }


    const changeSelectPartner = (option: PartnerOption) => {

        setPartner(option.partner ?? {
            partnerId: 0,
            partnerName: '',
            internalManager: { id: '', name: '' },
            externalManager: { id: '', name: '' },
        });

    }

    return (
        <>
            {partnerAssociated.isLoading ? <Loading /> : <>
                <div className="px-4">
                    <PageHeader title={"Associar parceira à gerentes"}
                        apiVersion={"1.0.0"}
                        breadcrumb={[{ label: 'Associar parceira à gerentes', url: "#" }]}>
                        <div className='pt-4'></div>
                    </PageHeader>

                    <div id="partner-select">
                        <label htmlFor="partner-select"
                            className="text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                            Parceiras
                        </label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue: string, callback: any) => {
                                setTimeout(() => {
                                    callback(partnersOptions.current.filter((option: any) =>
                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                    ))
                                }, 1000)
                            }}
                            placeholder="Parceira..."
                            onChange={(option: any) => changeSelectPartner(option)} />
                    </div>
                    {showForm && <>{refreshForm ? <Loading /> :
                        <div>
                            <ManagerSelectGroup
                                partner={partner}
                                internalLabel={internalOption}
                                externalLabel={externalOption} />
                        </div>}</>
                    }

                </div>
            </>
            }
        </>

    );
}

export default CommercialFormPage;