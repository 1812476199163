import { Link, useNavigate, useParams } from "react-router-dom";
import PresentationModal from "../../organisms/PresentationModal"
import { Fragment, useCallback } from "react";
import Loading from "../../ions/Loading";
import Tooltip from "../../atoms/Tooltip";
import { FaCalculator, FaFile } from "react-icons/fa";
import ATable from "../../organisms/ATable";
import useFetch from "../../atoms/useFetch";
import MiniLoading from "../../ions/MiniLoading";
import LCBalanceCalcValue from "../../molecules/LCBalanceCalcValue";

type ProviderResult = {
    id: string;
    providerName: string;
    score?: string;
    recommendation?: string;
    recommendationJustification?: string;
    documentReusedFromReferralId?: string[];
}

const BASE_FARMER_ANALISYS_URL = `${process.env.REACT_APP_FARMER_ANALYSIS_API_URL}/farmer-analysis/providers/result`;
const BASE_SETTLEMENT_URL = `${process.env.REACT_APP_SETTLEMENT_API_URL}/settlement`; //Está certo isso aqui? 

const BiroListModal = () => {

    const navigate = useNavigate();

    const getTranslatedRecommendation = (recommendation: string) => {
        switch (recommendation) {
            case "APPROVED":
                return "Aprovado";
            case "NOT_APPROVED":
                return "Reprovado";
            case "PENDING":
                return "Pendente";
            default:
                return "";
        }
    }

    const { referralId, partnerId } = useParams();

    let noCors = true
    const { isLoading, data, error, isTokenError } = useFetch(`${BASE_FARMER_ANALISYS_URL}/${referralId}`, noCors);

    const socialId = data ? data.socialId : "";

    const urlPartnerDebt = `${BASE_SETTLEMENT_URL}/partner/debt/${partnerId}`;
    const urlFarmerDebt = `${BASE_SETTLEMENT_URL}/farmer/debt/${socialId}`;

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    let modalData = data;

    const modalProviderRows = () => {
        if (modalData == null)
            return (
                <Fragment>
                    <tr className="border-b border-gray-300">
                        <td colSpan={4}>
                            <div className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                                Nenhum regitro encontrado!
                            </div>
                        </td>
                    </tr>
                </Fragment>
            );
        let sortByProviderName = (a: ProviderResult, b: ProviderResult) => {
            if (a.providerName > b.providerName) {
                return 1;
            } else if (a.providerName < b.providerName) {
                return -1;
            } else {
                return 0;
            }
        }
        modalData.providersResults.sort(sortByProviderName);
        return modalData.providersResults.map((row: ProviderResult, index: number) => (
            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : ''} hover:bg-slate-200 hover:shadow-md border-b border-gray-300`}>
                <td className="py-2 px-4" >{row.providerName}</td>
                <td className="py-2 px-4 text-right mr-4" >{row.score ? row.score : ''}</td>
                <td className="py-2 px-10">
                    {row.recommendation ? getTranslatedRecommendation(row.recommendation) : ''}
                </td>
                <td className="py-2 px-8 text-center grid grid-cols-2 gap-3">
                    <Link to={`../biro-variables/${modalData.referralId}/${row.providerName}`} >
                        <Tooltip content={"Ver Variáveis"}>
                            <FaCalculator style={{ color: 'cornflowerblue' }} />
                        </Tooltip>
                    </Link>

                    <Link
                        to={`../biro-document/${modalData.referralId}/${row.providerName}`}>
                        <Tooltip content={"Ver Documento"}>
                            <FaFile style={{ color: 'cornflowerblue' }} />
                        </Tooltip>
                    </Link>
                </td>
            </tr>
        ))
    }

    const modalValuesRows = () => {
        return (modalData ?
            <Fragment>
                <tr key='1' className='hover:bg-slate-200 hover:shadow-md border-b border-gray-300'>
                    <td className="py-2 px-4"  >Crédito Total Ponderado</td>
                    <td className="py-2 px-4" >{modalData.totalCreditWallet}</td>
                </tr>
                <tr key='2' className='bg-gray-50 hover:bg-slate-200 hover:shadow-md border-b border-gray-300'>
                    <td className="py-2 px-4" >Crédito Rural Ponderado</td>
                    <td className="py-2 px-4" >{modalData.ruralCreditWallet}</td>
                </tr>
                <tr key='3' className='hover:bg-slate-200 hover:shadow-md border-b border-gray-300'>
                    <td className="py-2 px-4" >
                        <Tooltip content='Montante total de operações em aberto'>
                            $ Aberto - CPF
                        </Tooltip>
                    </td>
                    <td className="py-2 px-4" >
                        <LCBalanceCalcValue
                            url={urlFarmerDebt}
                            jsonField="valueFormated"
                            toolTip="$$" />
                    </td>
                </tr>
                <tr key='4' className='bg-gray-50 hover:bg-slate-200 hover:shadow-md border-b border-gray-300'>
                    <td className="py-2 px-4" >
                        <Tooltip content='L.C. menos operações em aberto / atraso'>
                            Saldo $ Revenda
                        </Tooltip>
                    </td>
                    <td className="py-2 px-4" >
                        <LCBalanceCalcValue
                            url={urlPartnerDebt}
                            lessValue={modalData.partnerLimit}
                            jsonField="value"
                            formatBRL={true}
                            toolTip={modalData.partnerLimit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + " - $$"} />
                    </td>
                </tr>
            </Fragment> :
            <Fragment>
                <tr className="border-b border-gray-300">
                    <td colSpan={4}>
                        <div className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                            Nenhum regitro encontrado!
                        </div>
                    </td>
                </tr>
            </Fragment>)
    }

    return (
        <PresentationModal
            id='biro-list-modal'
            show={true}
            onClose={() => navigate(-1)}
            header="Birôs"
            height="600px"
        >
            {{
                body: (
                    <Fragment>
                        {isLoading ? <Loading /> :
                            <div className="p-4">
                                <div className='grid grid-cols-8 text-sm gap-2'>
                                    <span className="col-span-1 font-bold">#:</span>
                                    <span className="col-span-7">{data?.referralId}</span>

                                    <span className="col-span-1 font-bold">CPF:</span>
                                    <span className="col-span-7">{data?.socialId}</span>
                                </div>

                                <h2 className="font-bold mt-4 mb-2">Agrolend Score: {data?.score}</h2>
                                <div className="border border-gray-300">
                                    <ATable headers={[{
                                        id: "modal-table1-column2",
                                        title: "Nome",
                                        cssClass: 'text-left pl-4',
                                        canSort: false
                                    }, {
                                        id: "modal-table1-column3",
                                        title: 'Score',
                                        field: "score",
                                        width: '80px',
                                        canSort: false
                                    }, {
                                        id: "modal-table1-column4",
                                        title: 'Recomendação',
                                        field: "recommendation",
                                        width: '150px',
                                        canSort: false
                                    }, {
                                        id: "modal-table1-column5",
                                        title: 'Analisar',
                                        width: '100px',
                                        canSort: false
                                    }]}
                                        autoResize={false}
                                    >
                                        {modalProviderRows()}
                                    </ATable>
                                </div>

                                <h2 className="font-bold mt-4 mb-2">Valores</h2>
                                <div className="border border-gray-300">
                                    <ATable headers={[{
                                        id: "modal-table2-column1",
                                        title: "Variável",
                                        cssClass: 'text-left pl-4',
                                        field: 'nome',
                                        canSort: false
                                    }, {
                                        id: "modal-table2-column2",
                                        title: 'Valor',
                                        cssClass: 'text-left pl-4',
                                        field: "valor",
                                        canSort: false
                                    },]}
                                        autoResize={false}
                                    >
                                        {modalValuesRows()}
                                    </ATable>
                                </div>
                            </div>
                        }
                    </Fragment>
                )
            }}
        </PresentationModal >
    )
}

export default BiroListModal;