import { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { User } from "./User";
import { useAmplifly } from "./useAmplify";

const AuthProvider = ({ children }: { children: JSX.Element }) => {

    const [user, setUser] = useState<User | null>(null);
    const amplify = useAmplifly();

    useEffect(() => {
        console.log("AuthProvider...")
        const validateToken = async () => {
            const storageData = localStorage.getItem('authToken');
            if (storageData) {
                const data = await amplify.validateToken(storageData);
                if (data.user) {
                    setUser(data.user);
                }
            }
        }
        validateToken();
    }, []);

    const signin = async (username: string, password: string) => {
        const { user, token } = await amplify.signin(username, password);

        if (user && token) {
            setUser(user);
            setToken(token);
            return true;
        }
        return false;
    }

    const signout = async () => {
        setUser(null);
        setToken('');
        await amplify.signout();
    }

    const setToken = (token: string) => {
        localStorage.setItem('authToken', token);
    }

    return (
        <AuthContext.Provider value={{ user, signin, signout }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;