import { FC, Fragment } from "react";
import useFetch from "../atoms/useFetch";
import MiniLoading from "../ions/MiniLoading";
import Tooltip from "../atoms/Tooltip";

type LCBalanceCalcValueProps = {
    url: string;
    lessValue?: any;
    formatBRL?: boolean;
    toolTip?: string;
    jsonField: string;
}

const LCBalanceCalcValue: FC<LCBalanceCalcValueProps> = ({ url, lessValue, formatBRL: formatRBL = false, toolTip = "", jsonField }) => {

    const { isLoading, data, error, isTokenError } = useFetch(url);

    let value = "";

    function unformatCurrency(value: any) {
        return Number(value.replace("R$", "").replace(".", "").replace(",", "."));
    }

    if (data && jsonField in data) {
        let valueField = data[jsonField];
        if (lessValue != null) {
            if (typeof lessValue == "string")
                lessValue = unformatCurrency(lessValue);
            if (typeof valueField == "string")
                valueField = unformatCurrency(valueField);
            const minus = lessValue - valueField;
            if (formatRBL) {
                value = minus.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                toolTip = toolTip?.replace("$$", data[jsonField].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            } else {
                value = minus.toString();
                toolTip = toolTip?.replace("$$", data[jsonField]);
            }
        } else {
            if (formatRBL) {
                value = data[jsonField].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                toolTip = toolTip?.replace("$$", data[jsonField].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            } else {
                value = data[jsonField];
                toolTip = toolTip?.replace("$$", data[jsonField]);
            }
        }
    }

    return (
        <Fragment>
            {isLoading ? <MiniLoading /> :
                <Tooltip content={toolTip}>
                    {value.startsWith("-") ? <span className="text-red-500"> {value} </span> : value}
                </Tooltip>
            }
            {isTokenError ? <span className="text-rose-500">Erro de token</span> : null}
            {error ? <span className="text-rose-500">Erro de dados</span> : null}
        </Fragment>
    );
}

export default LCBalanceCalcValue;