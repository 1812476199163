import { useNavigate, useParams } from "react-router-dom";
import PresentationModal from "../../organisms/PresentationModal";
import { Fragment } from "react";
import useFetch from "../../atoms/useFetch";
import Loading from "../../ions/Loading";

type Provider = {
    id: number;
    providerName: string;
    score: string;
    recommendation?: string;
    recommendationJustification?: string;
    weight: string;
}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals`;

const ScoreAgrolendModal = () => {

    const navigate = useNavigate();
    const { referralId } = useParams();

    const url = BASE_REFERRAL_URL + `/partner-analysis-modal/${referralId}`;

    const { isLoading, data, error, isTokenError } = useFetch(url);

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    return (
        <PresentationModal
            id='score-agrolend-modal'
            show={true}
            onClose={() => navigate(-1)}
            header="Score Agrolend"
        >
            {{
                body: (
                    <Fragment>
                        {isLoading ? <Loading /> :
                            <div className='p-4'>
                                <div className='grid grid-cols-8 text-sm gap-2'>
                                    <span className="col-span-1 font-bold">#:</span>
                                    <span className="col-span-7">{data?.referralId}</span>

                                    <span className="col-span-1 font-bold">CPF:</span>
                                    <span className="col-span-7">{data?.socialId}</span>
                                </div>
                                <table style={{ width: '100%', tableLayout: 'fixed' }} className="mt-4">
                                    <thead>
                                        <tr className="bg-slate-200">
                                            <th className="p-2" align='left'>Provider</th>
                                            <th align='right'>Peso</th>
                                            <th align='right'>
                                                <span className="mr-8">Score</span>
                                            </th>
                                            <th align='center' style={{ width: "170px" }}>Score ponderado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.providersResults?.map((item: Provider, index: number) => (
                                            item.weight != null && (
                                                <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : ''} hover:bg-slate-200 hover:shadow-md`}>
                                                    <td>
                                                        <span className="ml-4">{item.providerName}</span>
                                                    </td>
                                                    <td className="text-right mr-10">
                                                        {parseFloat(item.weight) * 100}%
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="mr-8">
                                                            {parseFloat(item.score).toFixed(0)}
                                                        </span>
                                                    </td>
                                                    <td className="border-0 border-l border-slate-900 text-right">
                                                        <span className="pr-4">
                                                            {(parseFloat(item.weight) * parseFloat(item.score)).toFixed(2)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="border-0 border-t border-slate-900">
                                            <td colSpan={3} className="text-right">
                                                <span className="pr-1 font-bold">Agrolend Score:</span>
                                            </td>
                                            <td className="border-0 border-l border-slate-900 text-right">
                                                <span className="pr-4 font-bold">{data?.score ? data.score.toString() : ''}</span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        }
                    </Fragment>
                )
            }}
        </PresentationModal>
    );
}

export default ScoreAgrolendModal;