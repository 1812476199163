import { useEffect, useRef, useState } from 'react';
import PageForm from '../../templates/PageForm';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../atoms/useFetch';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileUpload from '../../molecules/FileUpload';
import ErrorItemList, { ErrorItemType } from '../../atoms/SpanList';
import { useSignal } from '@preact/signals-react';
import Select from 'react-select';


type Partner = {
    id: number;
    legalName: string;
    fantasyName: string;
}

type Form = {
    originId: string;
    guarantorIds: string[];
    file: (File | null);
    headerCount: number;
    columnIndex: number;
}

type ErrorField = {
    fieldName: string;
    message: string;
}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/analysis`;

const BATCH_ANALYSIS_LIST = "/analysis/batch-analysis/0/40/createdAt/DESC";
const BATCH_ANALYSIS_VIEW = "/analysis/batch-analysis/view";

const BatchAnalysisFormPage = () => {

    const navigate = useNavigate();
    const apiVersion = useRef('');
    const partners = useRef<Partner[]>([]);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const form = useSignal<Form>({
        originId: '',
        guarantorIds: [],
        file: null,
        headerCount: 7,
        columnIndex: 1
    });

    useEffect(() => {
        if (isSubmitted) {
            validateForm();
        }
    }, [form.value, isSubmitted]);

    const isLoadingForm = useSignal(false);
    const errorFields = useSignal<ErrorField[]>([]);

    const url = BASE_REFERRAL_URL + "/batch-analysis-form";
    const { isLoading, data, error, isTokenError } = useFetch(url);
    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    if (data) {
        apiVersion.current = `${data.apiName} - ${data.apiVersion}`;
        partners.current = data.partners;
    }

    const changeForm = (field: string, value: any) => {

        form.value = { ...form.value, [field]: value };

        removeErrorField(field);

        if (field === 'file') {
            removeErrorField('totalInvalids');
        }
    }

    const removeErrorField = (fieldName: string) => {
        if (errorFields.value == null) return;

        const idx = errorFields.value
            .findIndex(error => error.fieldName === fieldName);

        if (idx !== -1) {
            errorFields.value.splice(idx, 1);
        }
    }

    const cancelForm = () => {
        navigate(BATCH_ANALYSIS_LIST);
    }

    const validateForm = () => {
        const newErrorFields: ErrorField[] = [];

        if (!form.value.originId || form.value.originId === '-1') {
            newErrorFields.push({ fieldName: 'originId', message: 'Indique a parceira origem dos CPFs' });
        }

        if (!form.value.file) {
            newErrorFields.push({ fieldName: 'file', message: 'Arquivo é obrigatório' });
        }

        if (!form.value.guarantorIds || form.value.guarantorIds.length === 0) {
            newErrorFields.push({ fieldName: 'guarantorIds', message: 'Selecione pelo menos um avalista' });
        }

        errorFields.value = newErrorFields;
        return newErrorFields.length === 0;
    }

    //TODO remover essa validação no cliente (mover para o backend)
    const submitForm = async () => {
        setIsSubmitted(true);
        if (!validateForm()) {
            toast.error("Por favor, corrija os erros no formulário.");
            return;
        }

        isLoadingForm.value = true;
        const formData = new FormData();

        formData.append('originId', form.value.originId);
        form.value.guarantorIds.forEach((guarantorId) => {
            formData.append('guarantors', guarantorId);
        })

        if (form.value.file !== null)
            formData.append('file', form.value.file);
        formData.append('headerCount', form.value.headerCount.toString());
        formData.append('columnIndex', form.value.columnIndex.toString());

        const token = await Auth.currentSession()
            .then((user) => user.getAccessToken()
                .getJwtToken());

        const response = await fetch(BASE_REFERRAL_URL + "/batch", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (response.ok) {
            toast.info("Lote de análise em processamento...");
            const batchAnalysisId = await response.text();

            navigate(`${BATCH_ANALYSIS_VIEW}/${batchAnalysisId}`);
        } else {
            const { message, errors, title } = await response.json();
            toast.error(message);
            errorFields.value = errors ? errors : [];

            if (response.status === 413) {
                errorFields.value.push({ fieldName: 'file', message: title });
            }
        }
        isLoadingForm.value = false;
    }

    const getErrorMessage = (fieldName: string) => {
        if (errorFields.value) {
            const error = errorFields.value.find((error) => error.fieldName === fieldName);
            if (error) {
                return error.message;
            }
        }
        return '';
    }


    const DEFAULT_VALUE = { value: '-1', label: 'Selecione...' }



    return (

        <PageForm
            pageTitle='Nova análise em lote'
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[{ label: 'Análises em lote', url: BATCH_ANALYSIS_LIST }, { label: 'Nova análise em lote', url: '#' }]}
        >
            {{
                controlSlot: (

                    <div className='pt-4 text-right' >
                        <button type='button'
                            className='text-black bg-gradient-to-r from-slate-200 via-slate-300 to-slate-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={cancelForm}
                            disabled={isLoadingForm.value}
                        >
                            <div className="flex items-center">
                                Cancelar
                            </div>
                        </button>

                        <button type='button'
                            disabled={isLoadingForm.value}
                            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                            onClick={submitForm}>
                            <div className="flex items-center">
                                Processar
                            </div>
                        </button>
                    </div>
                ),

                formSlot: (

                    <div className="container mx-auto px-4 py-8">

                        <div className="mb-4">

                            <label htmlFor="originId">Origem:</label>
                            <Select
                                defaultValue={[DEFAULT_VALUE]}
                                name="originId"
                                instanceId="originId"
                                options={partners.current.map((partner) => ({
                                    id: partner.id.toString(),
                                    value: partner.id.toString(),
                                    label: partner.fantasyName,
                                }))}
                                className="basic-single"
                                classNamePrefix="select"
                                isLoading={isLoadingForm.value}
                                inputId="originId"
                                onChange={(e) => changeForm('originId', e?.value)}
                                placeholder="Selecione a parceira origem dos CPFs"

                            />

                            {getErrorMessage("originId") ?
                                <ErrorItemList
                                    itemType={ErrorItemType.ERROR}
                                    items={getErrorMessage("originId").split(',')
                                        .map((item) => ({ label: item }))}
                                />
                                : null}
                        </div>

                        <div className="mb-4">

                            <label htmlFor="guarantorIds">Avalista:</label>
                            <Select
                                inputId="guarantorIds"
                                name="guarantorIds"
                                isMulti={true}
                                options={partners.current.map((partner) => ({
                                    id: partner.id.toString(),
                                    value: partner.id.toString(),
                                    label: partner.fantasyName,
                                }))}
                                className="basic-single"
                                classNamePrefix="select"
                                isLoading={isLoadingForm.value}
                                onChange={(e) => changeForm('guarantorIds', e.map(e => e.value))}
                                placeholder="Selecione a(s) parceira(s) avalista(s) para analise"
                            />

                            {getErrorMessage("guarantorIds") ?

                                <ErrorItemList
                                    itemType={ErrorItemType.ERROR}
                                    items={getErrorMessage("guarantorIds").split(',')
                                        .map((item) => ({ label: item }))}
                                />

                                : null}

                        </div>

                        <div className="mt-10">
                            <FileUpload
                                id="file"
                                name="file"
                                label="Arquivo enviado:"
                                errorMessage={getErrorMessage('file')}
                                onUploadFile={(file) => changeForm('file', file)}
                                onRemoveFile={() => changeForm('file', null)}
                                accept=".xls, .xlsx"
                                info="XLS ou XLSX (MAX. 2MB)"
                                disabled={isLoadingForm.value}
                            />


                            {getErrorMessage("totalInvalids") ?

                                <ErrorItemList
                                    itemType={ErrorItemType.ERROR}
                                    label="CPFs inválidos"
                                    items={getErrorMessage("totalInvalids").split(',')
                                        .map((item) => ({ label: item }))}
                                />

                                : null}
                        </div>
                    </div>
                )
            }}
        </PageForm>
    );

}

export default BatchAnalysisFormPage;
