import FloatingActionButton from "../atoms/FloatingActionButton";
import { BiHelpCircle, BiAbacus, BiAddToQueue } from "react-icons/bi";
import MultiFloatingButton from "../molecules/MultiFloatingButton";
import packageJson from '../../package.json';

function Home() {

	const applicationName = "Backoffice";

	const actionButtons = [
		{
			color: 'blue',
			icon: BiHelpCircle,
			hint: 'Help',
			onClick: () => {
				alert('This is a help action!');
			},
		},
		{
			color: 'lime',
			icon: BiAbacus,
			hint: 'Calculator',
			onClick: () => {
				alert('This is a calculator action!');
			},
		},
	];

	return (
		<div className="h-full p-3">
			<input type="hidden" id="cyan" className="bg-cyan-400 hover:bg-cyan-600" />
			<input type="hidden" id="neutral" className="bg-neutral-400 hover:bg-neutral-600" />
			<input type="hidden" id="red" className="bg-red-400 hover:bg-red-600" />
			<input type="hidden" id="lime" className="bg-lime-400 hover:bg-lime-600" />
			<input type="hidden" id="violet" className="bg-violet-400 hover:bg-violet-600" />
			<input type="hidden" id="blue" className="bg-blue-600 hover:bg-blue-800" />

			<div className="mb-4">
				<h1 className="text-3xl font-bold text-gray-800">{applicationName}</h1>
				<span className="text-sm text-gray-600">Version: {packageJson.version}</span>
			</div>

			{/* Content Section */}
			<div className="flex flex-col items-center justify-center h-3/4">
				<p className="text-gray-600 text-lg text-center mb-6">
					Welcome to {applicationName}. Explore actions and features using the floating buttons below.
				</p>

				{/* Floating Action Buttons */}
				<MultiFloatingButton
					mainButtonColor="blue"
					mainButtonIcon={BiAddToQueue}
					mainButtonHint="Main Action"
					actionButtons={actionButtons}
				/>
			</div>


			<footer className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-gray-500 text-sm">
				<p>&copy; {new Date().getFullYear()} Agrolend. All rights reserved.</p>
			</footer>

		</div>
	);
}

export default Home;